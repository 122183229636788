<template>
  <aside class="filter">
    <slot name="iconBefore"></slot>
    <slot name="label"></slot>
    <slot name="iconAfter"></slot>
    <slot name="options"></slot>
    <slot></slot>
  </aside>
</template>

<script>
export default {
  name: "SelectFilter"
}
</script>
