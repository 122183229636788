<script>
import BulkCommissionDialog from '@/views/settings/components/BulkCommissionDialog.vue'
import Filters from './Filters.vue'
import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
import StoreConnection from './StoreConnection.vue'

export default {
  name: 'StorePayoutSettings',

  data() {
    return {
      valid: false,
    }
  },

  mixins: [payoutSettingsMixin],

  async mounted() {
    if(this.currentShop) {
      await this.fetchByStoreHandler(this.currentShop)
    }
  },

  watch: {
    'currentShop'(val) {
      if(this.connections.length > 0) return
      this.fetchByStoreHandler(val)
    }
  },

  computed: {
    isSaveStoreCommissionBtnDisabled() {
      let inValidConnections = this.connections.filter(connection => {
        const { type, value } = connection.store_commission_rate
        return (type !== null && (value === null || value === '')) || (type === null && value !== null)
      })

      return inValidConnections;
    }
  },

  components: {
    BulkCommissionDialog,
    Filters,
    StoreConnection,
  },
}
</script>

<template>
  <section class="payout-settings-section">
    <BulkCommissionDialog :selectedItems="selectedStoreCommissions" bulkCommissionTab="stores" />

    <v-dialog v-model="loadingStoresTab" width="500" persistent>
      <v-card light class="pa-5 pl-3 pr-3 text-center">
        <v-card-title class="pb-0">
          <v-img class="rotate" src="~@/assets/images/logo.svg" height="75px" contain></v-img>
        </v-card-title>
        <h3 class="pt-2 my-2">Fetching your connected stores</h3>
        <v-card-text class="pt-2">Loading lot's of stores can take up to 30 seconds. We'll have your results soon.</v-card-text>
      </v-card>
    </v-dialog>

    <div class="d-flex align-center my-4" v-if="selectedStoreCommissions.length > 0">
      <h4 class="mr-4 m-b-0" style="font-size: 16px;">{{ selectedStoreCommissions.length }} {{ selectedStoreCommissions.length > 1 ? 'stores' : 'store' }} selected</h4>
      <v-btn elevation="0" class="btn btn-border lg" @click="toggleCommissionDialogHandler">Set commission type and rate</v-btn>
    </div>

    <Filters />

    <section class="table">
      <v-form v-model="valid" ref="commissionForm">
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th style="width: 3%; padding-right: 11px;"></th>
                <th style="width: 11%;" class="text-center">Platform</th>
                <th style="width: 40%;">Store URL</th>
                <th style="width: 21%;">Commission type</th>
                <th style="width: 15%;">Rate</th>
                <th style="width: 10%;" class="text-right">Actions</th>
              </tr>
            </thead>
            <StoreConnection />
          </template>
        </v-simple-table>
      </v-form>

      <div v-if="isSaveStoreCommisionBtnVisible" class="m-t-4 text-right p-t-3" style="border-top: thin solid rgba(0, 0, 0, 0.12)">
        <v-btn :disabled="selectedStoreCommissions.length > 0" elevation="0" class="btn btn-border lg m-r-2" @click="fetchByStoreHandler(currentShop)" width="122px">Cancel</v-btn>
        <v-btn :disabled="selectedStoreCommissions.length > 0" elevation="0" class="btn btn-border inverse lg" @click="addStoreCommissionHandler" width="122px">Save</v-btn>
      </div>
    </section>
  </section>
</template>
