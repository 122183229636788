<script>
  import { IconPageFirst, IconPageLast, IconPageNext, IconPagePrev } from '@/icons'

  export default {
    name: 'Pagination',

    data() {
      return {
        entriesEndingAt: null,
        entriesStartingFrom: null,
      }
    },

    components: {
      IconPageFirst, IconPageLast, IconPageNext, IconPagePrev
    },

    props: ['pagination'],

    created() {
      this.setEntries()
    },

    watch: {
      'pagination'() {
        this.setEntries()
      }
    },

    methods: {
      goToFirstPage() {
        this.$emit('updateCurrentPage', 1)
      },

      goToLastPage() {
        this.$emit('updateCurrentPage', Math.ceil(this.pagination.total_count / this.pagination.per_page))
      },

      goToPrevPage() {
        this.$emit('updateCurrentPage', +this.pagination.current_page - 1)
      },

      goToNextPage() {
        this.$emit('updateCurrentPage', +this.pagination.current_page + 1)
      },

      setEntries() {
        const { per_page, current_page, total_count, next_page_url } = this.pagination
        this.entriesStartingFrom = (+per_page * +current_page) - (+per_page - 1)
        if(total_count < per_page) this.entriesEndingAt = total_count
        else this.entriesEndingAt = +per_page * +current_page

        if(!next_page_url) {
          this.entriesEndingAt = total_count
        }
      }
    }
  }
</script>

<template>
  <div class="paging p-2 display-flex justify-between align-center" v-if="pagination && pagination.total_count > 0">
    <h6 class="m-0" v-if="pagination"><span v-if="pagination.total_count > 0">{{ entriesStartingFrom }} - {{ entriesEndingAt }} of</span> {{ pagination.total_count }} Entries</h6>
    <ul class="display-flex p-l-0 m-0 justify-end">
      <li class="page paging__first" @click="goToFirstPage" :class="{ 'disabled': !pagination.previous_page_url }">
        <IconPageFirst />
      </li>
      <li class="page paging__prev" @click="goToPrevPage" :class="{ 'disabled': !pagination.previous_page_url }">
        <IconPagePrev />
      </li>
      <li class="page paging__page">
        {{ pagination.current_page }}
      </li>
      <li class="page paging__next" @click="goToNextPage" :class="{ 'disabled': !pagination.next_page_url }">
        <IconPageNext />
      </li>
      <li class="page paging__last" @click="goToLastPage" :class="{ 'disabled': !pagination.next_page_url }">
        <IconPageLast />
      </li>
    </ul>
  </div>
</template>
