<script>
import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
import { TrashIcon } from "@/icons";

export default {
  name: 'Products',

  mixins: [payoutSettingsMixin],

  props: {
    stores: {
      type: Object,
      required: true,
    }
  },

  components: {
    TrashIcon
  },

  methods: {
    getCommissionIcon(product) {
      const { type } = product.product_commission_rate
      if(type === 'percentage') return 'mdi-percent-outline'
      else if(type === 'flat_rate') return 'mdi-currency-usd'
      else return ''
    },

    selectBulkCommisssion($event, product, connection) {
      if($event) {
        product.isSelected = true
        const item = {
          commission_type: null,
          commission_value: null,
          connection_id: connection.connection_id,
          destination_store_id: this.currentShop.id,
          source_product_id: +product.external_product_id,
          source_store_id: connection.id
        }
        this.selectedProductCommissions.push(item)
      } else {
        const INDEX = this.selectedProductCommissions.findIndex(item => item.source_product_id === +product.external_product_id)
        this.selectedProductCommissions.splice(INDEX, 1)
        product.isSelected = false
      }
    },
  }
}
</script>

<template>
  <tbody>
    <tr v-for="product in products" :key="product.id" :class="{ 'selected': selectedProductCommissions.includes(product.id) }" class="commission-row">
      <td>
        <v-checkbox v-if="stores[product.store_id] && stores[product.store_id].platform === 'shopify'" @change="selectBulkCommisssion($event, product, stores[product.store_id])" dense hide-details v-model="product.isSelected" />
      </td>
      <td>{{ product.title }}</td>
      <td>{{ stores[product.store_id] && stores[product.store_id].store_domain }}</td>
      <td>
        <v-select
          :items="commissionTypeOptions"
          @input="checkForProductChanges"
          :disabled="selectedProductCommissions.length > 0"
          dense
          hide-details=""
          item-text="label"
          label="Select type"
          solo
          style="font-size: 13px;"
          v-if="product.product_commission_rate && stores[product.store_id] && stores[product.store_id].platform === 'shopify'"
          v-model="product.product_commission_rate.type" />
      </td>
      <td>
        <v-text-field
          :append-icon="getCommissionIcon(product)"
          :disabled="!product.product_commission_rate.type || selectedProductCommissions.length > 0"
          :rules="rules"
          @input="checkForProductChanges"
          dense
          type="number"
          label="Enter Rate"
          solo
          class="priceInput"
          style="font-size: 13px;"
          v-if="product.product_commission_rate && stores[product.store_id] && stores[product.store_id].platform === 'shopify'"
          v-model="product.product_commission_rate.value" />
      </td>
      <td class="text-right" style="vertical-align: middle;">
        <v-tooltip bottom v-if="product.product_commission_rate.value && product.product_commission_rate.type">
          <template v-slot:activator="{ on, attrs }">
            <span @click="deleteCommissionHandler(product.product_commission_rate.id)" style="cursor: pointer;" v-bind="attrs" v-on="on">
              <TrashIcon />
            </span>
          </template>
          <span>Clear commission</span>
        </v-tooltip>
      </td>
    </tr>
  </tbody>
</template>

<style scoped>
.priceInput >>> input[type="number"] {
  -moz-appearance: textfield;
}
.priceInput >>> input::-webkit-outer-spin-button,
.priceInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
