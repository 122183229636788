<script>
import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
import { TrashIcon } from "@/icons";

export default {
  name: 'StoreConnection',

  mixins: [payoutSettingsMixin],

  components: {
    TrashIcon,
  },

  methods: {
    getCommissionIcon(connection) {
      const { type } = connection.store_commission_rate
      if(type === 'percentage') return 'mdi-percent-outline'
      else if(type === 'flat_rate') return 'mdi-currency-usd'
      else return ''
    },

    clearCommissionHandler(connection) {
      connection.store_commission_rate = {
        type: null,
        value: null,
      }

      this.checkForStoreConnectionChanges()
    },

    selectBulkCommisssion($event, connection) {
      if($event) {
        connection.isSelected = true
        const item = {
          commission_type: null,
          commission_value: null,
          connection_id: connection.connection_id,
          destination_store_id: this.currentShop.id,
          source_product_id: null,
          source_store_id: connection.id
        }
        this.selectedStoreCommissions.push(item)
      } else {
        const INDEX = this.selectedStoreCommissions.findIndex(item => item.id === connection.id)
        this.selectedStoreCommissions.splice(INDEX, 1)
        connection.isSelected = false
      }
    },
  }
}
</script>

<template>
  <tbody>
    <tr v-for="connection in connections" :key="connection.id" :class="{ 'selected': selectedStoreCommissions.includes(connection.id) }" class="commission-row">
      <td>
        <v-checkbox
          @change="selectBulkCommisssion($event, connection)"
          dense
          hide-details
          v-model="connection.isSelected" />
        </td>
      <td>
        <div v-if="connection.platform === 'woocommerce'">
          <v-img max-height="25" max-width="25" src="@/assets/images/woo_logo.png" style="margin: 0 auto;" />
        </div>
        <div v-else-if="connection.platform === 'shopify'">
          <v-img max-height="25" max-width="25" src="@/assets/images/shopify_logo.png" style="margin: 0 auto;" />
        </div>
        <div v-else>{{ connection.platform }}</div>
      </td>
      <td>{{ connection.store_domain }}</td>
      <td>
        <v-select
          :items="commissionTypeOptions"
          @input="checkForStoreConnectionChanges"
          :disabled="selectedStoreCommissions.length > 0"
          dense
          hide-details=""
          item-text="label"
          label="Select type"
          solo
          style="font-size: 13px;"
          v-if="connection.store_commission_rate"
          v-model="connection.store_commission_rate.type" />
      </td>
      <td>
        <v-text-field
          :append-icon="getCommissionIcon(connection)"
          :disabled="!connection.store_commission_rate.type || selectedStoreCommissions.length > 0"
          :rules="rules"
          @input="checkForStoreConnectionChanges"
          dense
          type="number"
          label="Enter Rate"
          solo
          class="priceInput"
          style="font-size: 13px;"
          v-if="connection.store_commission_rate"
          v-model="connection.store_commission_rate.value" />
      </td>
      <td class="text-right" style="vertical-align: middle;">
        <v-tooltip bottom v-if="connection.store_commission_rate.value && connection.store_commission_rate.type">
          <template v-slot:activator="{ on, attrs }">
            <span @click="deleteCommissionHandler(connection.store_commission_rate.id)" style="cursor: pointer;" v-bind="attrs" v-on="on">
              <TrashIcon />
            </span>
          </template>
          <span>Clear commission</span>
        </v-tooltip>
      </td>
    </tr>
  </tbody>
</template>

<style scoped>
.priceInput >>> input[type="number"] {
  -moz-appearance: textfield;
}
.priceInput >>> input::-webkit-outer-spin-button,
.priceInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
