import { createHelpers } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'payoutSettings/getField',
  mutationType: 'payoutSettings/updateField'
})

export default {
  data() {
    return {
      commissionTypeOptions: [
        { value: 'flat_rate', label: 'Flat rate' },
        { value: 'percentage', label: 'Percentage' },
      ],
      rules: [
        value => /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(value) || 'Must be a valid number greater or equal to 0.0'
      ],
      loadingBulkCommission: false,
      loadingProductsTab: false,
      loadingStoresTab: false,
      storeTabLoadingCounter: 0,
      productTabLoadingCounter: 0
    }
  },

  computed: {
    ...mapFields([
      'connections',
      'connectionsPagination',
      'defaultCommission',
      'isCommissionModalVisible',
      'isSaveDefaultCommissionBtnVisible',
      'isSaveProductCommissionBtnVisible',
      'isSaveStoreCommisionBtnVisible',
      'isUnsavedModalVisible',
      'productFilters',
      'products',
      'productsCurrentPage',
      'productsPagination',
      'searchByStoreStr',
      'selectedProductCommissions',
      'selectedStoreCommissions',
      'selectedTab',
      'storeFilters',
      'tabs',
      'unMutatedConnections',
      'unMutatedDefaultCommission',
      'unMutatedProducts',
    ]),

    ...mapGetters("shop", ["currentShop"]),
  },

  methods: {
    randomNumber(min, max){
      const r = Math.random()*(max-min) + min
      return Math.floor(r)
    },

    arrayToObject (array, keyField) {
      return array.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
      }, {})
    },

    async fetchShop() {
      await this.$store.dispatch(`shop/loadCurrentShop`)
    },

    startProductsInterval() {
      let count = 0
      this.productTabLoadingCounter = setInterval(() => {
        count++

        if(count > 4 && this.products.length === 0) {
          this.loadingProductsTab = true
          clearInterval(this.productTabLoadingCounter)
          count = 0
        }
      }, 1000)
    },

    startStoresInterval() {
      let count = 0
      this.storeTabLoadingCounter = setInterval(() => {
        count++

        if(count > 4 && this.connections.length === 0) {
          this.loadingStoresTab = true
          clearInterval(this.storeTabLoadingCounter)
          count = 0
        }
      }, 1000)
    },

    async fetchByStoreHandler(currentShop) {
      const { search_str, sort_by, sort_by_desc } = this.storeFilters
      const payload = {
        current_store_id: currentShop && currentShop.id,
        search_str: encodeURIComponent(search_str),
        sort_by_desc,
        sort_by,
      }
      this.loadingStoresTab = true
      await this.$store.dispatch('payoutSettings/fetchByStore', payload)
      this.checkForStoreConnectionChanges()
      this.loadingStoresTab = false
    },

    async fetchByProductHandler(currentShop, page = 1) {
      const { connection_id, search_str, source_store_id } = this.productFilters
      const payload = {
        connection_id,
        destination_store_id: currentShop && currentShop.id,
        limiter: 10,
        page: page,
        search_str,
        source_store_id
      }
      this.loadingProductsTab = true
      await this.$store.dispatch('payoutSettings/fetchByProduct', payload)
      this.checkForProductChanges()
      this.loadingProductsTab = false
    },

    async addStoreCommissionHandler() {

      const payload = {
        current_store_id: this.currentShop.id,
        storeCommissions: []
      }

      payload.storeCommissions = this.connections.filter(connection => {
        const { type, value } = connection.store_commission_rate
        return (type !== null && value !== null)
      }).map(connection => {
        const { type, value } = connection.store_commission_rate
        const { id, connection_id } = connection

        return {
          commission_type: type,
          commission_value: +value,
          connection_id: connection_id,
          destination_store_id: this.currentShop.id,
          source_product_id: null,
          source_store_id: id,
        }
      })

      await this.$store.dispatch('payoutSettings/addStoreCommission', payload)
      await this.fetchByStoreHandler(this.currentShop)
    },

    async addProductCommissionHandler(stores) {
      const payload = {
        current_store_id: this.currentShop.id,
        storeCommissions: []
      }

      payload.storeCommissions = this.products.filter(product => {
        const { type, value } = product.product_commission_rate
        return (type !== null && value !== null)
      }).map(product => {
        const { type, value } = product.product_commission_rate
        const storeId = product.store_id
        const { connection_id, id } = stores[storeId]

        return {
          commission_type: type,
          commission_value: +value,
          connection_id: connection_id,
          destination_store_id: this.currentShop.id,
          source_product_id: +product.external_product_id,
          source_store_id: id,
        }
      })

      await this.$store.dispatch('payoutSettings/addStoreCommission', payload)
      await this.fetchByProductHandler(this.currentShop, this.productsCurrentPage)
    },

    async addBulkCommission(tab, commissionType, commissionValue) {
      let payload = {}
      this.loadingBulkCommission = true
      if(tab === 'stores') {
        this.selectedStoreCommissions.forEach(commission => {
          commission.commission_type = commissionType
          commission.commission_value = +commissionValue
        })

        payload = {
          current_store_id: this.currentShop.id,
          storeCommissions: this.selectedStoreCommissions
        }
      } else if(tab === 'products') {
        this.selectedProductCommissions.forEach(commission => {
          commission.commission_type = commissionType
          commission.commission_value = +commissionValue
        })

        payload = {
          current_store_id: this.currentShop.id,
          storeCommissions: this.selectedProductCommissions
        }
      }

      await this.$store.dispatch('payoutSettings/addStoreCommission', payload)

      if(tab === 'stores') {
        await this.fetchByStoreHandler(this.currentShop)
        this.selectedStoreCommissions = []
      } else if(tab === 'products') {
        await this.fetchByProductHandler(this.currentShop, this.productsCurrentPage)
        this.selectedProductCommissions = []
      }

      this.loadingBulkCommission = false
      this.toggleCommissionDialogHandler()
    },

    async addDefaultStoreCommissionHandler() {
      const current_store_id = this.currentShop.id
      const payload = {
        current_store_id: current_store_id,
        target_store_id: null,
        connection_id: null,
        commission_type: this.defaultCommission.type,
        commission_value: this.defaultCommission.value
      }

      await this.$store.dispatch('payoutSettings/addDefaultStoreCommission', { current_store_id, payload })
      await this.fetchShop()
      this.checkForDefaultCommissionChanges()
    },

    async deleteCommissionHandler(commissionId, tab) {
      await this.$store.dispatch('payoutSettings/deleteCommission', commissionId)
      if(this.selectedTab === 'store') {
        await this.fetchByStoreHandler(this.currentShop)
        this.checkForStoreConnectionChanges()
        this.selectedStoreCommissions = []
      } else if(this.selectedTab === 'product') {
        this.fetchByProductHandler(this.currentShop, this.productsCurrentPage)
        this.checkForProductChanges()
        this.selectedProductCommissions = []
      }
    },

    checkForStoreConnectionChanges() {
      this.isSaveStoreCommisionBtnVisible = JSON.stringify(this.connections) !== JSON.stringify(this.unMutatedConnections)
    },

    checkForProductChanges() {
      this.isSaveProductCommissionBtnVisible = JSON.stringify(this.products) !== JSON.stringify(this.unMutatedProducts)
    },

    checkForDefaultCommissionChanges() {
      this.isSaveDefaultCommissionBtnVisible = JSON.stringify(this.defaultCommission) !== JSON.stringify(this.unMutatedDefaultCommission)
    },

    setCurrentShopCommission(val) {
      const { default_commission_rate: { type, value } } = val
      this.defaultCommission.type = type
      this.defaultCommission.value = String(value)
      this.unMutatedDefaultCommission = JSON.parse(JSON.stringify(this.defaultCommission));
      this.checkForDefaultCommissionChanges()
    },

    toggleCommissionDialogHandler() {
      this.isCommissionModalVisible = !this.isCommissionModalVisible
    }
  }
}
