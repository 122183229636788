<script>
import payoutSettingsMixin from '../../payout/payoutSettingsMixin'

export default {
  name: 'DefaultPayoutSettings',

  mixins: [payoutSettingsMixin],

  watch: {
    'currentShop'(val) {
      this.setCurrentShopCommission(val)
      this.getCommissionIcon(val)
    }
  },

  methods: {
    getCommissionIcon(val) {
      if(!val) return
      const { type } = this.defaultCommission
      if(type === 'percentage') return 'mdi-percent-outline'
      else if(type === 'flat_rate') return 'mdi-currency-usd'
      else return ''
    },
  }
}
</script>

<template>
  <section class="payout-settings-section default p-2">
    <v-row class="p-y-4">
      <v-col cols="6" class="pb-0">
        <h4>Commission type</h4>
        <p class="color-light m-t-2 m-b-0">
          If no Store or Product Commission is set, Syncio will use the <br> Default Commission rate for Payout calculations
        </p>
      </v-col>
      <v-col cols="4" class="pb-0 pt-3">
        <v-select
          @input="checkForDefaultCommissionChanges()"
          :items="commissionTypeOptions"
          dense
          hide-details=""
          item-text="label"
          label="Select type"
          solo
          style="font-size: 13px; width: 300px;"
          v-model="defaultCommission.type" />

        <v-text-field
          @input="checkForDefaultCommissionChanges()"
          :append-icon="getCommissionIcon(currentShop)"
          :rules="rules"
          dense
          type="number"
          placeholder="Enter Rate"
          solo
          class="priceInput m-t-4"
          style="font-size: 13px; width: 135px"
          v-if="defaultCommission.type && defaultCommission.type !== ''"
          v-model="defaultCommission.value" />
      </v-col>
    </v-row>

    <div v-if="isSaveDefaultCommissionBtnVisible" class="m-t-4 text-right p-t-3" style="border-top: thin solid rgba(0, 0, 0, 0.12)">
      <v-btn elevation="0" class="btn btn-border lg m-r-2" width="122px" @click="fetchShop">Cancel</v-btn>
      <v-btn elevation="0" class="btn btn-border inverse lg" @click="addDefaultStoreCommissionHandler" width="122px">Save</v-btn>
    </div>
  </section>
</template>

<style scoped>
.priceInput >>> input[type="number"] {
  -moz-appearance: textfield;
}
.priceInput >>> input::-webkit-outer-spin-button,
.priceInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
