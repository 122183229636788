<script>
import payoutSettingsMixin from '../payout/payoutSettingsMixin'

export default {
  name: 'BulkCommissionDialog',

  mixins: [payoutSettingsMixin],

  data() {
    return {
      commissionType: null,
      commissionValue: null,
    }
  },

  props: [
    'bulkCommissionTab',
    'selectedItems',
  ],

  methods: {
    getCommissionIcon() {
      if(this.commissionType === 'percentage') return 'mdi-percent-outline'
      else if(this.commissionType === 'flat_rate') return 'mdi-currency-usd'
      else return ''
    },

    async addBulkCommissionHandler() {
      const { bulkCommissionTab, commissionValue, commissionType } = this
      await this.addBulkCommission(bulkCommissionTab, commissionType, commissionValue)
      this.commissionType = null
      this.commissionValue = null
    }
  }
}
</script>

<template>
  <v-overlay :value="isCommissionModalVisible" class="dialog dialog-primary commissions-modal">
    <v-card class="card-rounded not-a-link" width="500px">
      <v-toolbar height="71">
        <v-container class="px-0 d-flex justify-space-between">
          <v-card-title class="h3 pa-0">
            Set Commission for {{ selectedItems && selectedItems.length }} {{ bulkCommissionTab }}
          </v-card-title>
          <v-icon size="30px" color="white" class="mt-1" @click="toggleCommissionDialogHandler">close</v-icon>
        </v-container>
      </v-toolbar>

      <div class="d-flex flex-column justify-space-between dialog-body pa-8">
        <div style="font-size: 15px; color: #222; font-weight: bold; margin-bottom: 8px;">Commission type</div>
        <v-select
          attach=".commission-type-select"
          :items="commissionTypeOptions"
          hide-details=""
          item-text="label"
          label="Select type"
          solo
          light
          class="commission-type-select"
          style="font-size: 13px;"
          v-model="commissionType" />

        <div style="font-size: 15px; color: #222; font-weight: bold; margin-bottom: 4px; margin-top: 24px;">Rate</div>
        <p style="font-size: 15px; color: #222;">The rate you receive</p>
        <v-text-field
          :append-icon="getCommissionIcon()"
          :rules="rules"
          light
          type="number"
          label="Enter Rate"
          solo
          class="priceInput"
          style="font-size: 13px; width: 50%;"
          v-model="commissionValue" />

        <div class="d-flex justify-end pt-4" style="border-top: solid 1px #D6D6D6" v-if="commissionType && commissionValue">
          <v-btn elevation="0" class="btn btn-border lg" width="115px" @click="toggleCommissionDialogHandler">Cancel</v-btn>
          <v-btn :loading="loadingBulkCommission" elevation="0" class="btn btn-border inverse lg ml-3" width="115px" @click="addBulkCommissionHandler()">Confirm</v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>

<style scoped>
.priceInput >>> input[type="number"] {
  -moz-appearance: textfield;
}
.priceInput >>> input::-webkit-outer-spin-button,
.priceInput >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
