<script>
export default {
  name: 'BackToSettings',

  methods: {
    goToSettings() {
      this.$router.push({ name: 'Settings' })
    }
  }
}
</script>

<template>
  <a href="javascript:void(0);" class="link d-flex mb-6 mt-6" @click="goToSettings()" style="text-decoration: none; font-weight: 700; font-size: 15px; color: #3B82F6;">
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" >
      <path d="M10.1251 16.9062C10.0389 16.9066 9.95344 16.8898 9.8738 16.8568C9.79416 16.8237 9.72193 16.7751 9.66134 16.7137L4.41134 11.4637C4.28845 11.3407 4.21942 11.1739 4.21942 11C4.21942 10.8261 4.28845 10.6593 4.41134 10.5362L9.66134 5.28624C9.78575 5.17032 9.95029 5.10721 10.1203 5.11021C10.2903 5.11321 10.4525 5.18208 10.5728 5.30232C10.693 5.42255 10.7619 5.58477 10.7649 5.75478C10.7679 5.92479 10.7048 6.08933 10.5888 6.21374L5.80259 11L10.5888 15.7862C10.7117 15.9093 10.7808 16.0761 10.7808 16.25C10.7808 16.4239 10.7117 16.5907 10.5888 16.7137C10.5283 16.7751 10.456 16.8237 10.3764 16.8568C10.2968 16.8898 10.2113 16.9066 10.1251 16.9062V16.9062Z" fill="#3B82F6"/>
      <path d="M17.125 11.6562H4.875C4.70095 11.6562 4.53403 11.5871 4.41096 11.464C4.28789 11.341 4.21875 11.174 4.21875 11C4.21875 10.826 4.28789 10.659 4.41096 10.536C4.53403 10.4129 4.70095 10.3438 4.875 10.3438H17.125C17.299 10.3438 17.466 10.4129 17.589 10.536C17.7121 10.659 17.7812 10.826 17.7812 11C17.7812 11.174 17.7121 11.341 17.589 11.464C17.466 11.5871 17.299 11.6562 17.125 11.6562Z" fill="#3B82F6"/>
    </svg>
    Settings
  </a>
</template>
