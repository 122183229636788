<script>
import { mapState } from 'vuex'
import BulkCommissionDialog from '@/views/settings/components/BulkCommissionDialog.vue'
import Filters from './Filters.vue'
import Pagination from '@/components/UI/Pagination.vue'
import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
import Products from './Products.vue'

export default {
  name: 'ProductPayoutSettings',

  mixins: [payoutSettingsMixin],

  data() {
    return {
      valid: false,
      connectedStores: {}
    }
  },

  components: {
    BulkCommissionDialog,
    Filters,
    Pagination,
    Products,
  },

  async mounted() {
    if(this.currentShop) {
      await this.fetchByProductHandler(this.currentShop)
      this.connectedStores = this.arrayToObject(this.connectedShops, 'id')
    }
  },

  watch: {
    async 'currentShop'(val) {
      if(this.products.length > 0) return
      await this.fetchByProductHandler(val, this.productsCurrentPage)
    },

    'connectedShops'(val) {
      this.connectedStores = this.arrayToObject(val, 'id')
    }
  },

  computed: {
    ...mapState('shop', ['connectedShops']),
  },

  methods: {
    updateCurrentPageHandler(page) {
      this.productsCurrentPage = page
      this.fetchByProductHandler(this.currentShop, this.productsCurrentPage)
    }
  }
}
</script>

<template>
  <section class="payout-settings-section">

    <BulkCommissionDialog :selectedItems="selectedProductCommissions" bulkCommissionTab="products" />

    <v-dialog v-model="loadingProductsTab" width="500" persistent>
      <v-card light class="pa-5 pl-3 pr-3 text-center">
        <v-card-title class="pb-0">
          <v-img class="rotate" src="~@/assets/images/logo.svg" height="75px" contain></v-img>
        </v-card-title>
        <h3 class="pt-2 my-2">Fetching products</h3>
        <v-card-text class="pt-2">Loading lot's of products can take up to 30 seconds. We'll have your results soon.</v-card-text>
      </v-card>
    </v-dialog>

    <div class="d-flex align-center my-4" v-if="selectedProductCommissions.length > 0">
      <h4 class="mr-4 m-b-0" style="font-size: 16px;">{{ selectedProductCommissions.length }} {{ selectedProductCommissions.length > 1 ? 'stores' : 'store' }} selected</h4>
      <v-btn elevation="0" class="btn btn-border lg" @click="toggleCommissionDialogHandler">Set commission type and rate</v-btn>
    </div>

    <Filters />

    <section class="table">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th style="width: 3%; padding-right: 11px;"></th>
              <th style="width: 25%;">Product</th>
              <th style="width: 26%;">Store URL</th>
              <th style="width: 21%;">Commission type</th>
              <th style="width: 15%;">Rate</th>
              <th style="width: 10%;" class="text-right">Actions</th>
            </tr>
          </thead>

          <Products :stores="connectedStores" />
        </template>
      </v-simple-table>

      <!-- Pagination -->
      <Pagination :pagination="productsPagination" @updateCurrentPage="updateCurrentPageHandler" :class="{ 'shift': isSaveProductCommissionBtnVisible }" />

      <div v-if="isSaveProductCommissionBtnVisible" class="text-right p-t-3 p-y-3 pr-4" style="position: sticky; bottom: 0; border: 1px solid #E1E1E1; background: #F6F6F7; border-top: 0;">
        <v-btn :disabled="selectedProductCommissions.length > 0" elevation="0" class="btn btn-border lg m-r-2" @click="fetchByProductHandler(currentShop, productsCurrentPage)" width="122px">Cancel</v-btn>
        <v-btn :disabled="selectedProductCommissions.length > 0" elevation="0" class="btn btn-border inverse lg" @click="addProductCommissionHandler(connectedStores)" width="122px">Save</v-btn>
      </div>
    </section>
  </section>
</template>
