<script>
export default {
  name: "PageHeader",

  props: {
    pageDescription: {
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <section class="page-header">
    <h1 class="page--heading mb-2">
      <slot name="title"></slot>
    </h1>
    <p class="page--description mb-5 pb-3" v-if="pageDescription">{{ pageDescription }}</p>
  </section>
</template>
