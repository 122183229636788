<script>
  import { ChevronDown, SearchIcon, } from '@/icons'
  import { mapState } from 'vuex'
  import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
  import SelectFilter from '@/views/payouts/components/SelectFilter.vue'

  export default {
    name: 'Filters',

    mixins: [payoutSettingsMixin],

    data() {
      return {
        isSortFilterVisible: false,
        sortOptions: [
          { label: 'Store - A-Z', sort_by: 'store_domain', sort_by_desc: false },
          { label: 'Store - Z-A', sort_by: 'store_domain', sort_by_desc: true },
        ]
      }
    },

    components: {
      SearchIcon,
      SelectFilter,
      ChevronDown,
    },

    computed: {
      ...mapState('shop', ['connectedShops']),
    },

    methods: {
      onClickOutsideSortFilter() {
        this.isSortFilterVisible = false
      },

      async onClickSortHandler({ sort_by, sort_by_desc }) {
        this.storeFilters.sort_by = sort_by
        this.storeFilters.sort_by_desc = sort_by_desc
        await this.fetchByStoreHandler(this.currentShop)
        this.onClickOutsideSortFilter()
      }
    }
  }
</script>

<template>
  <section class="filters-listing">
    <ul class="d-flex pa-0">
      <li class="filters-listing__filter d-flex" data-filter="search">
        <div class="input-prepend d-flex" @click="fetchByStoreHandler(currentShop)">
          <SearchIcon />
        </div>
        <input type="text" v-model="storeFilters.search_str" class="input-field prepend-icon" placeholder="Search by URL" @keyup.enter="fetchByStoreHandler(currentShop)" />
      </li>

      <li class="filters-listing__filter" data-filter="sort" @click="isSortFilterVisible = true" v-click-outside="onClickOutsideSortFilter">
        <SelectFilter>
          <template #label>
            <div class="label">Sort
              <span></span>
            </div>
          </template>
          <template #iconAfter>
            <div class="icon-right absolute" style="height: 21px;">
              <ChevronDown />
            </div>
          </template>
          <template #options>
            <transition name="slide-x-reverse-transition">
              <ul class="filter-options" v-if="isSortFilterVisible">
                <li v-for="option in sortOptions" :key="option.label" :class="{ 'active': storeFilters.sort_by_desc === option.sort_by_desc }" @click="onClickSortHandler(option)">
                  {{ option.label }}
                </li>
              </ul>
            </transition>
          </template>
        </SelectFilter>
      </li>
    </ul>
  </section>
</template>
