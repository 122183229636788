<script>
import { LOAD_CURRENT_STORE_INVENTORIES } from '@/store/actions.type'
import { mapState } from 'vuex'
import BackToSettings from "@/views/settings/BackToSettings.vue";
import DefaultPayoutSettings from '../components/default/DefaultPayoutSettings.vue';
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import payoutSettingsMixin from './payoutSettingsMixin'
import ProductPayoutSettings from '../components/product/ProductPayoutSettings.vue';
import StorePayoutSettings from '../components/store/StorePayoutSettings.vue';
import Tabs from '@/components/UI/Tabs.vue'

export default {
  name: 'PayoutSettings',

  mixins: [payoutSettingsMixin],

  data() {
    return {
      forceLeaving: false,
      routeTo: null
    }
  },

  async created () {
    if(!this.currentShop) {
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });
    }
  },

  components: {
    BackToSettings,
    DefaultPayoutSettings,
    PageHeader,
    ProductPayoutSettings,
    StorePayoutSettings,
    Tabs,
  },

  computed: {
    ...mapState('shop', [ 'currentShop' ]),
  },

  methods: {
    changeTabHandler(tab) {
      this.selectedTab = tab.value
    },

    leavePageNow() {
      if (this.routeTo != null) {
        this.forceLeaving = true;
        this.isSaveStoreCommisionBtnVisible = this.isSaveProductCommissionBtnVisible = this.isSaveDefaultCommissionBtnVisible = this.isUnsavedModalVisible = false
        this.$router.push(this.routeTo);
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      let currentShop = vm.$store.getters['shop/currentShop']
      if(!currentShop) return
      const { default_commission_rate: { type, value } } = currentShop
      vm.$store.state.payoutSettings.defaultCommission.type = type
      vm.$store.state.payoutSettings.defaultCommission.value = String(value)
    })
  },

  beforeRouteLeave(to, from, next) {
    if((this.isSaveStoreCommisionBtnVisible || this.isSaveProductCommissionBtnVisible || this.isSaveDefaultCommissionBtnVisible) && !this.forceLeaving) {
      this.isUnsavedModalVisible = true
      this.routeTo = to
      return
    }
    next()
  }
}
</script>

<template>
  <section class="v2 payout-settings">
    <BackToSettings />

    <!-- Page Header -->
    <PageHeader pageDescription="">
      <template #title>Payout settings</template>
    </PageHeader>

    <h3 class="p-t-3 m-t-3 m-b-0" style="border-top: solid 1px #E0E0E0;">Commissions</h3>
    <p class="m-t-2 m-b-0">Commissions will be calculated on sales value; the total amount your customers pay for the product.</p>
    <p class="m-t-2 m-b-0">If no Product commissions are set, Syncio will use the Store commission in Payouts calculations for that product.</p>
    <p class="m-t-2 m-b-0">Commissions can only be set for Shopify stores, as Payouts is not available for Woo stores yet.</p>

    <!-- Tabs -->
    <aside class="m-t-4">
      <Tabs :tabs="tabs" :selectedTab="selectedTab" @changeTab="changeTabHandler" />
    </aside>

    <!-- Settings -->
    <transition name="slide-x-reverse-transition" mode="out-in" duration="200">
      <KeepAlive>
        <DefaultPayoutSettings v-if="selectedTab === 'default'" />
        <StorePayoutSettings v-if="selectedTab === 'store'" />
        <ProductPayoutSettings v-if="selectedTab === 'product'" />
      </KeepAlive>
    </transition>

    <v-dialog v-model="isUnsavedModalVisible" width="500">
      <v-card light class="pa-5 pl-3 pr-3">
        <v-card-title><h2>Leave page?</h2></v-card-title>
        <v-card-text>Changes you made will not be saved</v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="text-right mt-4">
              <v-btn elevation="0" class="btn btn-border lg" @click="isUnsavedModalVisible = false" width="115px">Stay</v-btn>
              <v-btn elevation="0" class="btn btn-border inverse lg ml-3" @click="leavePageNow" width="115px">Leave</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>