<script>
export default {
  name: 'Tabs',
  props: ['tabs', 'selectedTab'],
  methods: {
    changeTabHandler(tab) {
      this.$emit('changeTab', tab)
    }
  }
}
</script>

<template>
  <ul class="tabs pa-0">
    <li v-for="tab in tabs" :key="tab.value" class="tabs__item" :class="{ 'active router-link-exact-active': tab.value === selectedTab }" @click="changeTabHandler(tab)">
      {{ tab.label }}
    </li>
  </ul>
</template>
