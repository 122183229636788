<script>
  import { ChevronDown, SearchIcon, StoreLarge } from '@/icons'
  import { mapState } from 'vuex'
  import payoutSettingsMixin from '../../payout/payoutSettingsMixin'
  import SelectFilter from '@/views/payouts/components/SelectFilter.vue'

  export default {
    name: 'Filters',

    mixins: [payoutSettingsMixin],

    data() {
      return {
        isStoreFilterVisible: false,
        connectedStores: [],
        isSearchingStore: false,
        storeSearchTerm: '',
        storeDomain: null
      }
    },

    components: {
      StoreLarge,
      SearchIcon,
      SelectFilter,
      ChevronDown,
    },

    mounted() {
      this.connectedStores = this.connectedShops.filter(shop => shop.platform === 'shopify')
    },

    computed: {
      ...mapState('shop', ['connectedShops']),
    },

    watch: {
      'connectedShops'(val) {
        this.connectedStores = val.filter(shop => shop.platform === 'shopify')
      }
    },

    methods: {
      swapItems() {
        this.isSearchingStore = true
        //this.connectedStores = []
        document.querySelector(".search-input").focus();
        //this.connectedStores = [...this.connectedStores, ...this.connectedShops]
      },

      onClickOutsideStoreFilter() {
        this.isStoreFilterVisible = false
      },

      onSearchStoreHandler(event) {
        this.connectedStores = this.connectedShops.filter(store => store.platform === 'shopify').filter(store => store.store_domain.toLowerCase().includes(event.target.value.toLowerCase()))
      },

      closeFilters() {
        this.isStoreFilterVisible = false
      },

      async applyStoreFilter({ id, connection_id, store_domain }) {
        this.productFilters.source_store_id = id
        this.productFilters.connection_id = connection_id
        this.storeDomain = store_domain
        await this.fetchByProductHandler(this.currentShop)
        this.closeFilters()
      }
    }
  }
</script>

<template>
  <section class="filters-listing">
    <ul class="d-flex justify-start pa-0">
      <li class="filters-listing__filter d-flex" data-filter="search">
        <div class="input-prepend d-flex" @click="fetchByProductHandler(currentShop)">
          <SearchIcon />
        </div>
        <input type="text" v-model="productFilters.search_str" class="input-field prepend-icon" placeholder="Search by Product name" @keyup.enter="fetchByProductHandler(currentShop)" />
      </li>

      <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
        <SelectFilter>
          <template #iconBefore>
            <StoreLarge />
          </template>
          <template #label>
            <div class="label" @click="swapItems">
              <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
              <span v-show="!isStoreFilterVisible">{{ storeDomain || 'All Stores' }}</span>
            </div>
          </template>
          <template #iconAfter>
            <div class="icon-right absolute">
              <ChevronDown />
            </div>
          </template>
          <template #options>
            <transition name="slide-x-reverse-transition">
              <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                <li v-for="shop in connectedStores" :key="shop.id" @click="applyStoreFilter(shop)" :class="{ 'active': productFilters.source_store_id === shop.id }">
                  {{ shop.store_domain }}
                </li>
                <li class="not-found" key="store-not-found" v-if="connectedStores.length === 0">Store not found</li>
              </ul>
            </transition>
          </template>
        </SelectFilter>
      </li>
    </ul>
  </section>
</template>
